import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CodImage from "../assets/cod-image-game.svg";
import { breakpoints } from "../themes";
import GameSection from "../components/v2/gameSection";
import callAxios from "../hooks/Axios";
import { ENDPOINT } from "../constants/endpoints";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const HeaderTitle = styled.p`
  font-size: 40px;
  font-weight: bold;
  line-height: 35px;
  color: white;
  margin-bottom: 40px;
`;

const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

  ${breakpoints.down(breakpoints.tablet)} {
    grid-template-columns: repeat(auto-fill, minmax(159px, 1fr));
    grid-gap: 10px;
  }
`;

const GameLink = styled.a`
  display: flex;
  justify-content: center;
  margin: 8px;
  ${props =>
    props.isActive
      ? `justify-content: space-between; & img { position: relative; right: 50%; transform: translateX(50%); } & span { content: ''; width: 6px; height: 49px; background: #A77EFF; border-top-right-radius: 25px; border-bottom-right-radius: 25px; }`
      : `&span {display: none;}`};
`;

function GameListPage() {
  const [GameList, setGameList] = React.useState([]);
  const [activePage, setActivePage] = useState("");

  const getGameList = async () => {
    try {
      const response = await callAxios({
        method: "GET",
        url: `${ENDPOINT.GAME}`,
      });
      setGameList(response?.data || []);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getGameList();
  }, []);

  return (
    <MainWrapper>
      <HeaderTitle>Games</HeaderTitle>
      <SectionContainer>
        {GameList.map(item => (
          <GameLink href={`/#/gamepage/${item.id}`} rel="noreferrer" isActive={activePage == item.id} data-testid="game-item" >
            <GameSection gameName={item.visibleName} imageUrl={item.assets.gameThumbnail} />
          </GameLink>
        ))}
      </SectionContainer>
    </MainWrapper>
  );
}

export default GameListPage;
