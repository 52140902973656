import styled from "styled-components";

export const ChatContainer = styled.div`
  right: 60px;
  bottom: 90px;
  row-gap: 24px;
  display: flex;
  align-items: end;
  position: fixed;
  flex-direction: column;
  z-index: 999;

  @media (max-width: 768px) {
    right: 10px;
    bottom: 80px;
  }
`;

export const ChatButton = styled.button`
  width: 60px;
  height: 60px;
  border: none;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);
  &:active {
    outline: 3px solid #aa57ff;
  }
`;

export const ChatBox = styled.div`
  width: 278px;
  height: 384px;
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(63, 47, 170, 1);
  animation: heightAnim 0.2s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @keyframes heightAnim {
    0% {
      height: 0px;
    }
    100% {
      height: 294px;
    }
  }
`;

export const ChatBoxHeader = styled.div`
  z-index: 2;
  height: 95px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 5px 5px #3f2faa;
  border-bottom: 2px solid #342788;
`;

export const FlexRow = styled.div`
  display: flex;
  column-gap: 7px;
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 18px;
  div {
    font-size: 14px;
    font-weight: bolder;
    color: #fff;
  }
  small {
    font-size: 11px;
    color: #a498ed;
    font-weight: 325;
  }
`;

export const ChatMessagesContainer = styled.div`
  height: 100%;
  padding: 14px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  background-color: inherit;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const MessageRow = styled.div`
  font-size: 14px;
  color: #fff;
  span {
    color: ${props => props.color};
    margin-right: 8px;
  }
  small {
    font-size: 10px;
    color: #a498ed;
    margin-left: 8px;
  }
`;
export const NotifRow = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #a498ed;
  margin: 12px 0;
`;

export const Badge = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 30px;
  right: 0px;
  top: -5px;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fe2936;
`;

export const ChatInput = styled.input`
  border: none;
  height: 100%;
  padding-left: 14px;
  padding-right: 14px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #3f2faa;
  border-top: 2px solid #342788;
  box-shadow: 0 -5px 5px #3f2faa;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  color: #fff;
  &:focus {
    outline: 1px solid #aa57ff;
  }
`;

export const InputContainer = styled.div`
  height: 65px;
  position: relative;
  background-color: #3f2faa;
`;

export const SendButton = styled.button`
  top: 14px;
  right: 8px;
  border: none;
  cursor: pointer;
  padding: 2px 10px;
  position: absolute;
  background-color: transparent;
  &:hover,
  &:active,
  &:focus {
    border: transparent;
    outline: none;
  }
  &:disabled {
    cursor: default;
  }
`;

export const ChatHeaderImg = styled.img`
  height: 33px;
  width: 33px;
  border-radius: 7px;
  object-fit: cover;
`;

export const CloseChatButton = styled.button`
  outline: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;

export const DateTag = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  margin: 10px 0;
  padding: 4px 20px;
  background-color: #4e3cca;
  border-radius: 50px;
`;
