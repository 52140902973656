import React, { useContext, useEffect, useRef, useState } from "react";
import * as crypto from "crypto-js";

import * as S from "./styles";
import Chatbox from "./Chatbox";
import { MessagesIcon } from "../../../assets/svg";
import { SocketContext } from "../../../providers/SocketProvider";
import { useRecoilState } from "recoil";
import { sessionState } from "../../../state/globalState";
import { WSS_CRYPTO_SEED } from "../../../constants/env";
import { socketAuthState } from "../../../recoil/socketAuthState";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const Chat = ({ poolData, matchId }) => {
  const [showChatbox, setShowChatbox] = useState(false);
  const [roomChats, setRoomChats] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0);

  const [isSocketAuthenticated, setIsSocketAuthenticated] = useRecoilState(socketAuthState);

  const [didFailAuth, setDidFailAuth] = useState(false); // This bool will be used to re-emit the joinRoom event IN CASE the event failed once (which happens if the user tries to join a room while being unauthenticated in sockets). Using ref as state was not updating in time

  const [session] = useRecoilState(sessionState);

  const { socket } = useContext(SocketContext);

  function toggleChatbox() {
    setShowChatbox(prev => !prev);
  }
  function closeChatbox() {
    setShowChatbox(false);
  }

  const roomId = `${poolData.id}_${matchId}`;

  function handleMessageReceived(data) {
    // console.log("LOGGER - Socket Message Received: ", data, showChatbox);
    setRoomChats(prev => [...prev, data]);
    if (!showChatboxRef.current) {
      setUnreadMessages(prev => prev + 1);
    }
  }

  function handleJoinRoom(event) {
    if (event.error) {
      if (event?.data?.joined === false && event.data?.msg === "Trying to join a room but user is not authenticated.") {
        // If a user directly visits a pool page in the browser, it is possible that they are not authenticated in the websockets when they try to join a room
        // If that happens, the authenticateuser event should be emitted.
        // The useEffect will handle the functionality to reemit the join room event once the user has been successully authenticated (in sockets)
        const encryptedUserId = crypto.AES.encrypt(session?.user?.id, WSS_CRYPTO_SEED).toString();

        const encryptedSecToken = crypto.AES.encrypt(session?.access_token, WSS_CRYPTO_SEED).toString();

        const encryptedIp = crypto.AES.encrypt(session?.ip, WSS_CRYPTO_SEED).toString();

        const obj = { userId: encryptedUserId, secToken: encryptedSecToken, ipAddress: encryptedIp };
        socket.emit("authenticateUser", obj);

        setDidFailAuth(true);
      }
    }
    if (!event.error) {
      setRoomChats(event.data?.roomHistory);
      if (event.data?.roomHistory?.length > 1) setShowChatbox(true); // Open chatbox if there are previous chats
    }
  }

  const showChatboxRef = useRef(showChatbox);

  // Keep the ref updated with the latest value of showChatbox. Need this as handleMessageReceived is not capturing the latest value of the showChatbox state
  useEffect(() => {
    showChatboxRef.current = showChatbox;

    if (showChatbox) setUnreadMessages(0);
  }, [showChatbox]);

  useEffect(() => {
    socket.emit("joinRoom", {
      roomId: roomId,
    });
    socket.on("roomMessage", handleMessageReceived);
    socket.on("joinRoomResponse", handleJoinRoom);

    // The following will run when a user is authenticated.
    socket.on("authenticateUserResponse", result => {
      if (!didFailAuth) return;

      if (result?.data?.authenticated) {
        socket.emit("joinRoom", {
          roomId: roomId,
        });
        setIsSocketAuthenticated(true);
      }
    });

    return () => {
      socket.off("roomMessage", handleMessageReceived);
      socket.emit("leaveRoom", {
        roomId: roomId,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };
  }, [roomId]);

  const chatRef = useRef(null);
  useOnClickOutside(chatRef, event => {
    if (closeChatbox) {
      closeChatbox();
    }
  });

  return (
    <S.ChatContainer ref={chatRef}>
      {showChatbox && <Chatbox roomChats={roomChats} roomId={roomId} poolData={poolData} closeChatbox={closeChatbox} />}
      <S.ChatButton onClick={toggleChatbox}>
        <MessagesIcon />
        {unreadMessages > 0 && <S.Badge>{unreadMessages}</S.Badge>}
      </S.ChatButton>
    </S.ChatContainer>
  );
};

export default Chat;
