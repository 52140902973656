import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AccordionArrowIcon } from "../../../assets/svg";
import { breakpoints } from "../../../themes";

const AccordionWrapper = styled.div`
  font-size: 15px;
  color: white;
`;

const AccordionItemWrapper = styled.div`
  background-color: #3f2faa;
  border-radius: 10px;
  margin-bottom: 12px;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 360px;
  }
`;

const AccordionHeader = styled.div`
  height: 81px;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 18px;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  svg {
    transform: ${props => (props.visible ? "rotate(90deg)" : "rotate(0deg)")};
    transition: all 0.15s linear;
    width: 20px;
  }
`;

const AccordionContent = styled.div`
  transition: all 0.15s linear;
  overflow: hidden;
  color: #d4cedf;
  height: ${props => (props.visible ? "fit-content" : "0")};
  padding: ${props => (props.visible ? "0px 29px 25px 61px" : "0px 29px 0px 61px")};
  display: flex;
  /* justify-content: center; */
  align-items: center;
`;

const AccordionHeaderText = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  margin: 0;
  color: #ffffff;
  width: calc(100% - 20px);
`;

const AccordionItem = ({ question, searchTerm }) => {
  const [visibility, setVisibility] = useState(false);

  const handleToggleVisibility = () => {
    setVisibility(!visibility);
  };

  useEffect(() => {
    if (!searchTerm) setVisibility(false);
  }, [searchTerm]);

  const converter = str => {
    let newStr = str;

    // Replacing escaped characters
    newStr = newStr
      .replace(/\\n/g, "\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\r")
      .replace(/\\t/g, "\t")
      .replace(/\\b/g, "\b")
      .replace(/\\f/g, "\f");

    return <div dangerouslySetInnerHTML={{ __html: newStr }} />;
  };

  converter(question.answer);

  return (
    <AccordionItemWrapper>
      <AccordionHeader onClick={handleToggleVisibility} visible={visibility}>
        <AccordionArrowIcon />
        <AccordionHeaderText data-testid="faq_answer">{question.title}</AccordionHeaderText>
      </AccordionHeader>
      <AccordionContent visible={visibility}>
        <div style={{ whiteSpace: "pre-wrap" }}>{converter(question.answer)}</div>
      </AccordionContent>
    </AccordionItemWrapper>
  );
};

const Accordion = ({ questions, searchTerm }) => {
  return (
    <AccordionWrapper>
      {questions.map((question, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <AccordionItem key={index} question={question} searchTerm={searchTerm} />
      ))}
    </AccordionWrapper>
  );
};

export default Accordion;
