import React from "react";
import * as S from "./styles";
import Flex from "../../../components/v2/common/Flex";
import Container from "../../../components/v2/common/Container";
import { Text } from "../../../components/v2/common/Text";
import { sessionState } from "../../../state/globalState";
import { useRecoilState } from "recoil";
import { ProviderImage } from "../ProfilePage";
import { IconButton } from "../../../components/v2/common/Button";
import { CopyIcon } from "../../../assets/svg";
import { copyTextToClipboard } from "../../../helpers/utils";
import moment from "moment";

const AccountSettings = () => {
  const [session] = useRecoilState(sessionState);
  const user = session?.user;

  if (!user) return null;

  return (
    <S.AccountSettingsContainer>
      <Container marginBottom="22px">
        <Text fontSize="24px">Details</Text>
      </Container>

      <S.Separator />
      <Container flex columnGap="100px">
        <S.DetailRowName>Name</S.DetailRowName>
        <Container width="100%" flex>
          <strong>{user.nick || user.name}</strong>
        </Container>
      </Container>
      <S.Separator />
      {user.email && (
        <>
          <Container flex columnGap="100px">
            <S.DetailRowName>Email Address</S.DetailRowName>
            <Container width="100%" flex>
              <strong>{user.email}</strong>
            </Container>
          </Container>
          <S.Separator />
        </>
      )}
      {user.extProvider && (
        <>
          <Container flex columnGap="100px">
            <S.DetailRowName>Account</S.DetailRowName>
            <Container width="100%" flex columnGap="12px">
              <S.SocialImage>
                <ProviderImage provider={user.extProvider} />
              </S.SocialImage>
              <strong>{user.extData?.name}</strong>
            </Container>
          </Container>
          <S.Separator />
        </>
      )}
      {user.address && (
        <>
          <Container flex columnGap="100px">
            <S.DetailRowName>Wallet Address</S.DetailRowName>
            <Container width="100%" flex columnGap="12px" marginRight="12px" alignItems="center">
              <S.AddressText>{user.address}</S.AddressText>
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  copyTextToClipboard(user.address);
                }}
              >
                <CopyIcon />
              </IconButton>
            </Container>
          </Container>
          <S.Separator />
        </>
      )}
      {user.whatsApp && user.whatsApp?.isVerified && (
        <>
          <Container flex columnGap="100px">
            <S.DetailRowName>WhatsApp</S.DetailRowName>
            <Container width="100%" flex columnGap="12px" marginRight="12px" alignItems="center">
              <strong>{`+${user.whatsApp?.countryDialCode} ${user.whatsApp?.phoneNumber}`}</strong>
            </Container>
          </Container>
          <S.Separator />
        </>
      )}
      {user.createdOn && (
        <>
          <Container flex columnGap="100px">
            <S.DetailRowName>Joined On</S.DetailRowName>
            <Container width="100%" flex>
              <strong>{moment(user.createdOn).format("DD MMMM YYYY")}</strong>
            </Container>
          </Container>
          <S.Separator />
        </>
      )}
    </S.AccountSettingsContainer>
  );
};

export default AccountSettings;
