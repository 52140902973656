import React, { createContext, useState } from "react";
import { io } from "socket.io-client";
import { useRecoilState } from "recoil";
import { sessionState } from "../state/globalState";
import * as crypto from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import { WEBSOCKET_SERVER, WSS_CRYPTO_SEED } from "../constants/env";
import { ENDPOINT } from "../constants/endpoints";

export const SocketContext = createContext(null);

const SocketProvider = ({ children }) => {
  // const [isSocketAuthenticated, setIsSocketAuthenticated] = useState(false); // Exporting and using state variables from this provider was causing issues with socket
  let key = sessionStorage.getItem("socket_key");
  if (!key) {
    let uuid = uuidv4();
    key = crypto.AES.encrypt(uuid, WSS_CRYPTO_SEED).toString();
    sessionStorage.setItem("socket_key", key);
  }
  const socket = io.connect(`${WEBSOCKET_SERVER}${key}`);
  return <SocketContext.Provider value={{ socket, key }}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
